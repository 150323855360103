import React from 'react';
import styled from 'styled-components';

import Image from '../atoms/Image';
import Pill from '../atoms/Pill';
import AuthorImage from '../molecules/AuthorImage';

const AuthorLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
`;

const BlogPostLayout = styled.article`
  display: grid;
  gap: 24px;
  padding: 16px 0;
`;

const Body = styled.article`
  display: grid;
  gap: 16px;
`;

const PostInfo = styled.div`
  padding-left: 16px;
  display: grid;
  grid-gap: 8px;
`;

type Post = {
  readonly id: string;
  readonly excerpt: string | null;
  readonly html: string | null;
  readonly fields: { readonly slug: string | null } | null;
  readonly frontmatter: {
    readonly title: string | null;
    readonly date: string | null;
    readonly description: string | null;
    readonly imageName: string | null;
    readonly twitterImage: string | null;
    readonly metaImage: string | null;
    readonly category: string | null;
    readonly author: string | null;
    readonly readTime: string | null;
  } | null;
};

type BlogPostProps = {
  post: Post | null;
};

const defaultBlogImage = '../../images/cuttings.png';

const BlogPost = ({ post }: BlogPostProps) => (
  <BlogPostLayout>
    {post?.frontmatter?.category && (
      <Pill text={post?.frontmatter?.category} to={`/blog/${post?.frontmatter?.category}`} />
    )}
    {post?.frontmatter?.title && <h1 itemProp="headline">{post?.frontmatter?.title}</h1>}
    {post?.frontmatter?.description && <p>{post?.frontmatter?.description}</p>}
    <Image
      alt="Cuttings"
      imageName={post?.frontmatter?.imageName || defaultBlogImage}
      imgStyle={{
        borderTopRightRadius: '60px',
      }}
    />
    <AuthorLayout>
      <AuthorInfo>
        <AuthorImage name={post?.frontmatter?.author} />
        <PostInfo>
          <strong>{post?.frontmatter?.author || 'Plant Co.'}</strong>
          {post?.frontmatter?.date && <p>{post?.frontmatter?.date}</p>}
        </PostInfo>
      </AuthorInfo>
      {post?.frontmatter?.readTime && <p>{post?.frontmatter?.readTime} read</p>}
    </AuthorLayout>
    {post?.html && (
      // eslint-disable-next-line react/no-danger
      <Body dangerouslySetInnerHTML={{ __html: post?.html }} itemProp="articleBody" />
    )}
  </BlogPostLayout>
);

export default BlogPost;

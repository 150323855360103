import React from 'react';
import { Link } from 'gatsby';

import Image from '../atoms/Image';

type Post = {
  readonly excerpt: string | null;
  readonly fields: { readonly slug: string | null } | null;
  readonly frontmatter: {
    readonly date: string | null;
    readonly title: string | null;
    readonly imageName: string | null;
  } | null;
};

type BlogOverviewProps = {
  posts: readonly Post[];
};
const BlogOverview = ({ posts }: BlogOverviewProps) => (
  <section>
    {posts.map((post) => (
      <article key={post?.fields?.slug} itemScope itemType="https://schema.org/Article">
        {post?.frontmatter?.imageName && (
          <Image alt="Cuttings" imageName={post?.frontmatter?.imageName} loading="eager" />
        )}
        <header>
          {post?.frontmatter?.date && <p>{post?.frontmatter?.date}</p>}
          {post?.frontmatter?.title && (
            <Link itemProp="url" to={post?.fields?.slug || '/'}>
              {post?.frontmatter?.title}
            </Link>
          )}
        </header>
        {/* eslint-disable react/no-danger */}
        {post?.excerpt && (
          <p
            dangerouslySetInnerHTML={{
              __html: post?.excerpt,
            }}
            itemProp="description"
          />
        )}
      </article>
    ))}
  </section>
);

export default BlogOverview;

import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import AppHelmet from '../AppHelmet';
import GlobalStyle from '../ui/atoms/GlobalStyle';
import Logo from '../ui/atoms/Logo';
import mediaQueries from '../ui/atoms/mediaQueries';
import Footer from '../ui/organisms/Footer';

const PageLayout = styled.div`
  ${mediaQueries.mobile`
    padding: 16px;
  `}

  ${mediaQueries.tablet`
    padding: 32px;
  `}

  ${mediaQueries.laptop`
    padding: 32px 64px;
  `}

  ${mediaQueries.desktop`
    padding: 32px 96px;
  `}
`;

const LogoLayout = styled.div`
  display: flex;
  justify-content: center;
`;

const LogoLink = styled(Link)`
  svg {
    height: 250px;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
`;

type LayoutProps = {
  children: ReactNode;
  description?: string | undefined | null;
  image?: string | undefined | null;
  robotMetaContent?: string | undefined | null;
  schemaMarkup?: object;
  title?: string | undefined | null;
  twitterImage?: string | undefined | null;
};

const Layout = ({
  children,
  description,
  image,
  robotMetaContent,
  schemaMarkup,
  title,
  twitterImage,
}: LayoutProps) => (
  <>
    <AppHelmet
      description={description}
      image={image}
      robotMetaContent={robotMetaContent}
      schemaMarkup={schemaMarkup}
      title={title}
      twitterImage={twitterImage}
    />
    <GlobalStyle />
    <PageLayout>
      <main role="main">
        <LogoLayout>
          <LogoLink aria-label="Logo" to="/">
            <Logo fill="#000000" />
          </LogoLink>
        </LogoLayout>
        {children}
      </main>
      <Footer />
    </PageLayout>
  </>
);

Layout.defaultProps = {
  description: undefined,
  image: undefined,
  robotMetaContent: undefined,
  schemaMarkup: {},
  title: undefined,
  twitterImage: undefined,
};

export default Layout;

import React from 'react';
import { Link } from 'gatsby';

import Layout from '../templates/Layout';

const schemaMarkup = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  logo: 'www.plantcoshop.ca/logo.png',
  name: 'Plant Co.',
  url: 'www.plantcoshop.ca',
};

const Home = () => (
  <Layout schemaMarkup={schemaMarkup} title="Home - Plant Co.">
    <Link to="/blog/">Blog</Link>
  </Layout>
);

export default Home;

import React from 'react';

type LogoProps = {
  fill: string;
};
const Logo = ({ fill }: LogoProps) => (
  <svg
    id="Layer_1"
    version="1.1"
    viewBox="0 0 187.09 338.62"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    y="0px"
  >
    <g id="bUyiUB.tif">
      <g>
        <g>
          <path
            d="M6.06,39.4c1.21-0.97,1.57-2.12,1.65-3.62c0.12-2.22,0.2-4.47,0.58-6.64c0.92-5.16,3.01-9.69,6.74-13.18
				c2.19-2.05,4.43-4.05,6.66-6.05c2.64-2.37,5.39-4.59,8.76-5.58c4.5-1.32,8.99-2.72,13.55-3.76c8.13-1.84,14.94,0.8,20.31,7.69
				c2.84,3.65,3.99,8.07,4.79,12.64c0.08,0.47,0.33,0.9,0.51,1.35c0.01,0,0.01,0,0.02,0c0.04,0.1,0.08,0.21,0.13,0.31
				c0.04,0.09,0.09,0.19,0.13,0.28c0.05,0.11,0.1,0.21,0.16,0.31c0.07,0.12,0.15,0.22,0.23,0.33c0.03,0.04,0.06,0.07,0.09,0.11
				c-0.24,1.24-0.65,2.46-1.64,3.5c-0.25-0.81-0.49-1.46-0.64-2.14c-0.66-2.89-1.12-5.85-1.96-8.68
				C63.14,6.19,55.48,0.97,45.87,2.59c-3.35,0.56-6.6,1.77-9.92,2.59c-2.95,0.73-6,0.92-8.74,2.56c-2.51,1.5-4.7,3.38-6.9,5.94
				c4.21,0.44,8.04,0.83,11.87,1.23c3.32,0.34,6.57,0.98,9.61,2.52c1.71,0.86,2.32,2.12,1.85,3.57c-0.55,1.72-1.71,2.33-3.62,1.77
				c-1.39-0.41-2.75-0.97-4.11-1.49c-4.48-1.71-8.91-3.61-13.46-5.08c-2.71-0.87-5.34-0.26-7.43,2.21
				c-1.93,2.29-3.39,4.86-4.28,7.78c-1.01,3.27-1.61,6.63-1.47,10.33c3.42-1.11,6.67-2.18,9.92-3.22c5.49-1.76,10.84-4.1,16.64-4.56
				c3.29-0.26,6.53-0.09,9.68,1.05c1.29,0.47,1.86,1.46,1.64,2.95c-0.22,1.47-1.21,2.02-2.39,2.1c-1.35,0.1-2.72,0.07-4.07-0.01
				c-4.61-0.27-9.22-0.72-13.84-0.83c-4.49-0.11-8.52,1.56-12.09,4.53c-1.69,1.4-3.41,2.75-5.15,4.14c-0.6-0.87-1.04-1.5-1.5-2.17
				c-2.55,1.4-4.28,3.43-5.3,6.18c-1.22,3.27-1.07,6.69-0.73,10.06c0.27,2.61,1.01,5.17,1.6,8.07c0.42-0.48,0.58-0.59,0.65-0.75
				c2.4-4.94,6.26-8.05,10.96-9.9c7.45-2.94,14.96-5.66,22.45-8.49c2.13-0.81,4.3-1.68,6.55-1.12c1.03,0.26,2.25,1.06,2.72,2
				c0.33,0.66-0.22,2.18-0.79,2.95c-0.62,0.84-1.66,1.53-2.64,1.8c-4.15,1.14-8.35,2.1-12.53,3.11c-5.56,1.35-11.11,2.71-16.15,5.79
				c-6.55,4-9.5,10.15-8.18,17.5c0.67,3.71,2.44,6.71,5.38,9.14c0.25-0.6,0.48-1.03,0.62-1.49c0.75-2.28,1.81-4.32,3.55-5.91
				c2.44-2.21,4.84-4.48,7.27-6.69c0.35-0.32,0.77-0.6,1.21-0.73c1.64-0.5,2.41,0.32,1.92,2.07c-0.2,0.7-0.54,1.41-0.98,1.95
				c-1.5,1.82-3.09,3.53-4.6,5.34c-2.23,2.66-4.07,5.55-4.68,9.19c-0.28,1.69,0.04,2.45,1.51,3.07c3.17,1.34,6.08,3.16,8.57,5.68
				c1.38,1.41,2.61,3,3.97,4.59c0.16-0.26,0.31-0.44,0.4-0.66c1.12-2.69,3.06-3.84,5.74-3.59c2.37,0.21,4.75,0.34,7.11,0.65
				c1.38,0.18,1.67-0.43,1.65-1.77c-0.05-5.08-0.02-10.16-0.02-15.24c0.14,0,0.27,0,0.41,0c0,4.88,0.01,9.75-0.01,14.63
				c0,1.31,0.37,2.47,1.67,2.57c4.27,0.32,9.5-0.84,11.42-6.66c1.63-4.95,1.83-10.06,1.21-15.16c-0.53-4.36-1.48-8.66-2.23-12.98
				c-0.21-1.2-0.26-2.39,0.89-3.09c1.19-0.73,2.49-0.86,3.53,0.18c0.81,0.8,1.47,1.84,2,2.89c1.88,3.72,2.19,7.84,2.29,11.95
				c0.15,6.49-1.17,12.67-3.69,18.56c-0.27,0.63-0.37,1.35-0.6,2.26c0.57-0.12,0.82-0.12,1-0.23c4.22-2.51,8.5-4.9,12.59-7.64
				c1.08-0.72,1.96-2.43,2.22-3.85c0.79-4.25,0.71-8.56,0.02-12.85c-0.82-5.11-3.13-9.5-5.41-13.94c-1.04-2.02-1.86-4.2-2.55-6.39
				c-0.41-1.29-0.17-2.64,1.27-3.3c1.2-0.55,2.37-0.23,3.31,1.13c5.82,8.46,8.31,17.92,7.15,28.46c-0.14,1.29-0.02,2.61-0.02,4.31
				c0.55-0.53,0.73-0.68,0.88-0.86c3.12-3.51,6.22-7.03,9.35-10.52c2.03-2.27,2.93-4.83,2.22-8.04c-1.5-6.77-3.88-12.88-9.45-17.05
				c-1.19-0.89-2.07-2.48-2.75-3.94c-0.88-1.88,0.2-3.38,2.14-3.16c2.15,0.24,4.1,1.08,5.58,2.86c2.15,2.6,2.89,5.91,3.66,9.2
				c-0.64-0.7-1.13-1.5-1.39-2.38c-0.95-3.17-2.57-5.78-5.3-7.36c-0.92-0.53-2.07-0.6-3.11-0.88c-0.12,0.16-0.24,0.32-0.36,0.48
				c0.33,0.9,0.48,1.97,1.03,2.65c1.06,1.33,2.37,2.43,3.55,3.66c1.69,1.77,4.38,2.44,4.95,5.49c0.46-0.28,0.8-0.5,1.18-0.73
				c0.41,1.03,0.78,1.97,1.19,3c0.43-0.33,0.67-0.48,0.87-0.68c4.18-4.08,5.82-9.23,4.73-15.12c-0.84-4.54-2.26-8.97-3.44-13.43
				c-0.78-2.94-2.59-4.93-5.17-6.01c-8.91-3.74-12.87-2.78-18.47,4.87c-4.04,5.52-7.83,11.27-11.54,17.05
				c-5.02,7.82-9.57,15.96-15.19,23.37c-4.85,6.4-8.78,13.62-13.09,20.5c-0.33,0.52-0.44,1.21-0.59,1.84
				c-0.62,2.68-1.21,5.36-1.82,8.04c-0.18,0.02-0.36,0.04-0.54,0.07c-0.14-0.42-0.43-0.85-0.38-1.25c0.15-1.3,0.36-2.6,0.64-3.88
				c1.41-6.45,4.48-11.99,8.06-17.25c4.94-7.26,10.04-14.4,14.87-21.74c5.13-7.8,9.94-15.85,15.07-23.65
				c2.08-3.17,4.37-6.3,7.04-8.86c6.86-6.6,19-3.37,22.3,5.97c1.66,4.7,2.7,9.68,3.68,14.61c1.21,6.08-0.22,11.26-4.94,15.27
				c-2.13,1.81-2.77,4.56-1.8,7.44c1.44,4.28,0.29,7.69-2.67,10.67c-3.88,3.9-7.71,7.85-11.49,11.86c-0.81,0.85-1.28,2.06-1.98,3.05
				c-0.62,0.87-1.19,1.93-2.03,2.42c-4.77,2.77-9.6,5.45-14.46,8.04c-0.99,0.53-2.2,0.54-3.72,0.89c0.47-1.1,0.69-1.76,1.02-2.34
				c4.52-7.85,5.61-16.4,4.64-25.44c-0.34-3.14-1.53-5.82-3.17-8.32c-0.3-0.46-1.12-0.84-1.61-0.74c-0.4,0.08-0.86,0.91-0.93,1.47
				c-0.09,0.71,0.12,1.5,0.33,2.21c2.34,8.16,3.28,16.4,1.61,24.88c-1.12,5.66-4.04,9.35-9.62,9.63c-1.61,0.08-3.21,0.41-4.93,0.65
				c1.42,17,5.2,33.29,9.36,49.68c0.3-0.3,0.4-0.35,0.42-0.43c0.13-0.48,0.26-0.97,0.37-1.46c2.23-9.75,4.81-19.38,8.96-28.41
				c4.21-9.18,9.42-17.58,17-23.91c8.57-7.16,18.23-10.79,29.09-9.12c11.39,1.75,19.33,8.61,23.62,20.27
				c0.5,1.36,0.96,2.74,1.39,3.99c1.65-2.01,3.08-4.1,4.83-5.8c2.26-2.19,4.74-4.13,7.2-6.06c6.42-5.06,15.92-2.21,18.83,5.75
				c1.86,5.1,1.91,10.6,2.68,16.31c-0.76-0.62-1.24-1-1.7-1.4c-2.02-1.71-3.93-3.65-6.6-4.13c-1.91-0.34-3.54,0.26-5.06,1.86
				c0.36,0.15,0.52,0.21,0.67,0.27c6.84,2.54,13.72,4.95,20.51,7.65c6.09,2.42,9.46,8.4,9.07,15.42c-0.26,4.67-1.28,9.12-3.29,13.25
				c-0.47,0.97-1.29,1.75-1.95,2.61c0,0,0,0,0,0c0.55,0.88,1.13,1.74,1.65,2.65c1.93,3.37,2.62,6.92,1.1,10.71
				c-1.45,3.62-2.93,7.23-4.41,10.83c-0.7,1.68-1.45,3.34-2.17,4.99c-1.88-0.74-1.66-2.4-1.69-3.77c-0.06-3.14-1.1-5.82-2.88-8.21
				c-3.15-4.22-6.28-8.45-9.5-12.6c-1.74-2.24-3.64-4.34-5.48-6.48c-0.65-0.75-1.2-0.59-1.64,0.45c0.98,1.42,1.97,2.89,3,4.32
				c3.9,5.45,7.92,10.79,11.68,16.35c3.43,5.06,3.27,10.4-0.32,15.29c-1.71,2.33-3.67,4.49-5.73,6.47
				c-4.68,4.51-6.73,10.09-6.37,16.8c0.05,0.88,0.01,1.76,0.01,2.91c-1.38-0.49-2.44-0.84-3.48-1.25
				c-6.19-2.44-12.44-4.65-19.04-5.27c-2.6-0.25-5.23-0.22-7.84-0.3c-1.21-0.04-1.98-0.54-1.88-2.02c0.15-2.12,0.08-4.29,0.48-6.35
				c0.78-3.95,1.75-7.86,2.78-11.74c1.4-5.24,2.95-10.44,4.38-15.67c0.13-0.47-0.14-1.06-0.22-1.6c-0.51,0.23-1.23,0.3-1.5,0.72
				c-1.59,2.44-3.26,4.85-4.57,7.47c-3.8,7.57-5.2,15.78-5.13,24.33c0.01,0.77,0,1.55,0,2.32c-0.14,0.09-0.29,0.18-0.43,0.27
				c-3.54-2.15-7.1-4.26-10.62-6.45c-3.54-2.2-4.74-5.8-4.49-9.95c0.4-6.68,2.44-12.73,6.21-18.11c3.05-4.36,6-8.8,8.86-13.31
				c0.6-0.95,0.66-2.35,0.82-3.56c0.03-0.25-0.59-0.81-0.95-0.86c-2.11-0.28-3.19,1.45-4.32,2.9c-6.44,8.28-10.03,17.95-11.66,28.56
				c-0.36,2.31-0.75,4.62-1.12,6.93c-0.15,0.05-0.3,0.09-0.45,0.14c-0.4-0.62-0.88-1.19-1.2-1.86c-0.68-1.38-1.13-2.92-1.94-4.2
				c-2.53-4.01-5.13-7.97-7.81-11.86c-2.78-4.04-3.22-7.88-0.76-12.13c1.78-3.07,3.84-5.97,5.96-8.79
				c5.61-7.44,11.57-14.52,18.83-20.15c0.43-0.34,0.78-0.8,1.17-1.2c-0.06-0.12-0.12-0.24-0.17-0.36c-1.38,0.4-2.73,0.91-4.13,1.19
				c-2.89,0.57-5.17,2.18-7.14,4.48c-3.38,3.93-6.84,7.79-10.25,11.7c-2.97,3.4-5.16,7.31-6.43,11.81
				c-0.19,0.67-0.54,1.29-0.81,1.93c-1.3-3.95-2.34-7.82-3.16-11.74c-1.09-5.23-2.34-10.46-2.06-15.9c0.16-3.09,1.28-5.68,3.64-7.55
				c2.58-2.05,5.55-3.11,8.6-3.93c3.57-0.96,7.18-1.78,10.74-2.76c1.46-0.4,2.71-1.28,3.45-2.93c-0.6-0.39-1.08-0.86-1.63-1.04
				c-5-1.65-9.92-1.74-14.3,1.77c-2.2,1.76-4.03,4.07-6.05,6.11c-0.84,0.85-1.72,1.65-2.84,2.71c-0.1-0.95-0.28-1.54-0.2-2.1
				c0.52-3.85,0.85-7.75,1.71-11.51c0.76-3.31,1.97-6.54,3.27-9.65c0.93-2.22,2.74-3.36,5.14-3.21c1.07,0.07,2.16,0.03,3.22-0.14
				c2.93-0.47,5.51-1.89,7.94-3.63c2.39-1.71,4.97-2.84,7.86-2.89c6.84-0.12,13.65,5.27,16.56,12.66c0.98,2.48,2.6,4.66,3.94,6.98
				c-0.25-1.61-0.42-3.23-0.78-4.81c-3.69-16.42-15.97-23.77-28.05-23.78c-8.42,0-16.07,2.88-22.85,8.34
				c-7.02,5.67-11.97,13.19-16.11,21.41c-4.98,9.86-7.97,20.5-10.33,31.37c-0.43,2-1.07,4.11-0.86,6.08
				c0.67,6.17,1.7,12.29,2.58,18.43c1.4,9.77,1.67,19.57,0.86,29.42c-0.75,9.1-2.24,18.06-4.29,26.91c-0.2,0.87-0.65,1.67-0.99,2.51
				c-0.14-0.03-0.29-0.05-0.43-0.08c-0.13-0.61-0.32-1.21-0.4-1.83c-0.32-2.81-0.66-5.62-0.91-8.44c-0.57-6.55-1.35-13.1-1.56-19.67
				c-0.23-6.99-0.16-14.02,0.25-21c0.38-6.45,1.27-12.88,2.11-19.29c0.77-5.88,1.22-11.75-0.59-17.45
				c-2.71-8.56-3.93-17.47-5.49-26.29c-1.02-5.75-1.89-11.54-2.86-17.3c-0.06-0.35-0.43-0.9-0.68-0.92
				c-2.93-0.25-5.87-0.6-8.81-0.55c-2.29,0.03-3.5,1.71-3.54,4.22c-0.02,1.09,0,2.19,0,3.28c-0.15,0.07-0.29,0.14-0.44,0.21
				c-0.57-0.79-1.22-1.53-1.71-2.38c-2.45-4.26-5.59-7.73-9.65-10.19c-1.26-0.77-2.59-1.42-3.83-2.21
				c-2.03-1.29-2.57-2.58-1.73-4.96c0.78-2.2,1.61-4.49,2.9-6.35c1.9-2.74,4.24-5.12,6.36-7.69c0.52-0.63,0.93-1.38,1.39-2.07
				c-0.11-0.13-0.22-0.26-0.34-0.39c-0.3,0.14-0.65,0.21-0.9,0.42c-1.54,1.32-3.04,2.7-4.58,4.02c-3.77,3.23-6.12,7.2-5.59,12.9
				c-0.55-0.32-0.97-0.51-1.33-0.79c-4.44-3.45-7.07-8.15-8.01-13.95c-0.9-5.54,0.5-10.23,4.83-13.48c2.72-2.03,5.61-3.88,8.61-5.37
				c6.19-3.07,12.92-4.23,19.45-6.12c2.5-0.72,4.93-1.74,7.36-2.72c0.32-0.13,0.7-0.93,0.59-1.2c-0.14-0.36-0.76-0.75-1.14-0.71
				c-1.84,0.19-3.71,0.36-5.5,0.83c-2.22,0.58-4.4,1.37-6.54,2.22c-6.21,2.48-12.49,4.8-18.57,7.64c-4.67,2.18-8.07,6-9.48,11.54
				c-0.1,0.38-0.38,0.7-0.83,1.5c-0.53-1.46-1.01-2.43-1.24-3.47c-0.7-3.15-1.35-6.31-1.93-9.48C-0.83,49.96,0.51,43.85,6.06,39.4z
				 M30.58,17.28c2.94,1.27,5.9,2.53,8.89,3.64c0.79,0.29,1.74,0.04,2.81,0.04c-0.51-1.84-1.32-2.59-2.67-2.9
				c-2.87-0.66-5.7-1.51-8.56-2.21c-0.39-0.1-0.86,0.2-1.3,0.31C30.03,16.55,30.22,17.13,30.58,17.28z M28.99,32.33
				c5.26,0.3,10.51,0.61,15.77,0.85c0.35,0.02,0.73-0.49,1.1-0.75c-0.33-0.47-0.59-1.28-0.99-1.36c-2.06-0.43-4.16-1.01-6.22-0.9
				c-3.01,0.16-6,0.85-8.99,1.33c-0.25,0.04-0.47,0.19-0.71,0.3C28.96,31.98,28.97,32.16,28.99,32.33z M10.09,41.17
				c1.84-1.43,3.57-2.78,5.29-4.12C12.06,37.09,9.83,38.58,10.09,41.17z M79.81,78.75c0.03,0.63,0.14,1.26,0.21,1.89
				c0.17,0,0.34-0.01,0.51-0.01c0.05-2.52,0.27-5.05,0.13-7.56c-0.26-4.78-1.04-9.49-2.93-13.84c-0.98-2.26-2.38-4.33-3.74-6.37
				c-0.5-0.76-1.42-1.18-2.46-2c-0.56,3.33,0.55,5.63,1.73,7.95c1.39,2.75,2.85,5.47,4.04,8.33C78.84,70.8,79.64,74.71,79.81,78.75z
				 M131.75,116.08c-0.85-1.97-1.96-3.84-2.65-5.87c-1.75-5.19-5.53-7.45-10.13-8.55c-3.71-0.89-7.24-0.38-10.48,1.92
				c-1.17,0.83-2.42,1.55-3.65,2.28c-2.26,1.34-4.62,2.21-7.25,1.55c-1.79-0.45-3.15,0.34-4.11,1.87c-0.83,1.33-1.74,2.72-2.16,4.23
				c-1.16,4.16-2.09,8.39-3.07,12.61c-0.13,0.57-0.02,1.2-0.02,2.05c0.42-0.32,0.72-0.5,0.97-0.75c1.54-1.48,3.05-3,4.62-4.45
				c5.01-4.64,10.68-4.92,16.59-2.69c0.89,0.34,1.91,1.58,2.07,2.57c0.23,1.38-0.73,2.65-2.03,3.12c-4.16,1.5-8.35,2.89-12.53,4.33
				c-1.78,0.61-3.59,1.14-5.33,1.85c-4.56,1.87-6.79,5.67-6.01,10.87c0.93,6.19,2.18,12.33,3.3,18.49c0.05,0.27,0.21,0.51,0.38,0.93
				c0.23-0.41,0.41-0.63,0.5-0.9c1.46-4.24,4.04-7.61,6.92-10.79c2.81-3.12,5.57-6.3,8.28-9.52c2.47-2.94,5.23-5.17,9.08-5.41
				c0.85-0.05,1.67-0.61,2.5-0.94c0.26-0.1,0.51-0.28,0.78-0.35c0.97-0.23,2.09-0.48,2.64,0.65c0.5,1-0.02,1.99-0.8,2.68
				c-2.54,2.25-5.18,4.35-7.62,6.71c-6.61,6.39-12.02,13.92-17.13,21.71c-2.46,3.76-2.56,7.71-0.08,11.48
				c2.77,4.21,5.72,8.29,8.6,12.42c0.08,0.11,0.23,0.15,0.59,0.39c0.23-0.99,0.47-1.87,0.64-2.77c1.73-9.03,4.55-17.58,9.75-25
				c1.29-1.85,2.76-3.6,4.37-5.12c0.84-0.79,2.14-1.18,3.28-1.34c1.53-0.21,2.15,0.54,2.44,2.22c0.35,2.01-0.36,3.75-1.31,5.31
				c-1.78,2.92-3.68,5.75-5.6,8.56c-2.55,3.76-5.29,7.37-6.95,11.75c-1.47,3.88-2.22,7.91-1.53,12.04c0.29,1.76,1.05,3.64,2.11,4.99
				c2.5,3.2,6.04,4.81,9.75,6.65c0-0.83,0.01-1.41,0-1.99c-0.25-10.25,2.73-19.45,7.85-27.91c0.73-1.21,1.71-2.28,2.72-3.23
				c0.88-0.82,2.11-1.21,3.12-0.43c1.07,0.83,1.29,2.19,0.86,3.57c-1.28,4.13-2.66,8.22-3.86,12.37c-1.69,5.82-2.71,11.79-3.2,17.86
				c-0.2,2.42-0.18,2.42,2.07,2.45c8.63,0.13,16.81,2.44,24.78,5.87c0.43,0.18,0.86,0.35,1.4,0.57c0.06-0.34,0.17-0.57,0.14-0.78
				c-0.95-5.92,0.81-10.92,4.46-15.21c1.87-2.2,3.92-4.22,5.87-6.34c1.78-1.93,3.2-4.12,3.78-6.83c0.9-4.24-0.41-7.95-2.71-11.22
				c-3.77-5.37-7.72-10.59-11.57-15.89c-0.63-0.87-1.26-1.8-1.65-2.81c-0.24-0.6-0.25-1.6,0.07-2.08c0.32-0.47,1.32-0.87,1.79-0.68
				c1.27,0.54,2.74,1.12,3.58,2.19c5.01,6.37,9.89,12.86,14.73,19.39c0.74,0.99,1.05,2.36,1.54,3.56c0.33,0.82,0.63,1.67,0.94,2.5
				c0.19,0.14,0.37,0.29,0.56,0.43c0.27-0.53,0.48-1.12,0.83-1.59c5.73-7.66,4.71-18.59-2.41-24.71c-3.52-3.02-7.25-5.75-10.77-8.77
				c-1.72-1.47-3.27-3.2-4.73-4.97c-3.05-3.68-6.59-6.57-10.77-8.58c-1.18-0.57-2.16-1.63-3.49-2.67c2.27-1.18,4.21-1.29,6.06-0.5
				c6.09,2.59,12.21,5.11,18.15,8.06c5.17,2.56,8.5,7.17,10.41,12.98c0.16,0.47,0.33,0.94,0.52,1.4c0.03,0.07,0.15,0.1,0.54,0.34
				c0.61-2.4,1.32-4.69,1.77-7.03c0.59-3.01,0.45-6.06-0.28-9.05c-1.1-4.51-3.72-7.59-7.76-9.09c-6.76-2.51-13.56-4.91-20.34-7.34
				c-1.85-0.66-2.06-1.22-0.92-2.97c1.57-2.4,3.91-2.7,6.29-2.2c1.99,0.41,3.9,1.26,6.08,1.99c-0.23-1.8-0.45-3.57-0.67-5.34
				c-0.82-6.51-3.76-10.43-9.05-11.96c-3.23-0.93-6.2-0.27-8.86,1.94c-0.41,0.34-0.78,0.75-1.22,1.02
				c-4.97,3.15-8.03,8.08-10.76,13.36c-0.26,0.5-0.87,0.8-1.33,1.18c1.88,6.95,3.74,13.9,5.66,20.83
				c2.32,8.37,3.56,16.96,4.72,25.59c1.05,7.86,2.44,15.68,3.76,23.49c1.08,6.37,2.28,12.73,3.41,19.09
				c0.07,0.38,0.01,0.78-0.27,1.26c-0.2-0.56-0.47-1.11-0.59-1.69c-1.76-8.55-3.63-17.07-5.2-25.66c-1.37-7.5-2.23-15.1-3.59-22.6
				c-2.01-11.08-4.49-22.06-7.88-32.75C134.92,122.79,133.22,119.47,131.75,116.08z M181.79,158.96c-0.07-3.69-1.3-6.99-3.02-10.08
				c-2.59-4.65-6.47-7.65-11.03-9.69c-3.93-1.75-7.85-3.53-11.81-5.2c-1.49-0.63-3.06-1-4.6-1.48c-0.07,0.16-0.13,0.32-0.2,0.48
				c0.12,0.07,0.23,0.15,0.35,0.2c4.31,1.54,7.71,4.51,10.67,8.11c2.69,3.26,5.56,6.24,8.9,8.75c3.37,2.54,6.47,5.5,9.7,8.27
				C181.07,158.57,181.44,158.74,181.79,158.96z M55.63,230.46c5.42-23.47,5.98-46.62,0.94-70.23
				C52.48,169.25,51.78,221.85,55.63,230.46z"
            fill={fill}
          />
        </g>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M25.73,264.95c3.07,0,5.52,0.56,7.36,1.67c1.83,1.11,2.75,3.08,2.75,5.9c0,1.66-0.48,3.23-1.43,4.7
			c-0.96,1.47-2.38,2.66-4.28,3.57c-1.9,0.91-4.16,1.36-6.79,1.36h-8.04v-1.88h7.05c2.07,0,3.78-0.38,5.12-1.15
			c1.35-0.77,2.32-1.75,2.91-2.94c0.59-1.19,0.89-2.43,0.89-3.71c0-2.04-0.62-3.49-1.86-4.35c-1.24-0.86-2.89-1.29-4.96-1.29h-4.14
			l1.88-0.8l-6.96,24.39c-0.28,0.97-0.42,1.74-0.42,2.3c0,0.69,0.18,1.21,0.54,1.57c0.36,0.36,0.92,0.61,1.69,0.75
			c0.77,0.14,1.84,0.23,3.22,0.26l-0.47,1.6H4.48l0.47-1.6c1.16-0.06,2.06-0.18,2.7-0.35c0.64-0.17,1.19-0.54,1.65-1.1
			c0.45-0.56,0.85-1.43,1.2-2.58l6.02-20.96c0.19-0.66,0.28-1.22,0.28-1.69c0-0.63-0.14-1.07-0.42-1.34
			c-0.28-0.27-0.67-0.45-1.15-0.54c-0.49-0.09-1.15-0.16-2-0.19l0.47-1.6H25.73z"
          fill={fill}
        />
        <path
          d="M37.19,297.33c-0.94,0-1.65-0.24-2.14-0.73c-0.49-0.49-0.73-1.18-0.73-2.09c0-0.5,0.08-1.11,0.24-1.83
			c0.16-0.72,0.42-1.61,0.8-2.68l6.91-20.35c0.28-0.81,0.41-1.46,0.4-1.95c-0.02-0.49-0.19-0.85-0.52-1.08
			c-0.33-0.24-0.82-0.38-1.48-0.45l-1.83-0.14l0.38-1.27l7.57-1.22h1.13l-9.02,26.74c-0.28,0.78-0.47,1.38-0.56,1.79
			c-0.09,0.41-0.14,0.71-0.14,0.89c0,0.28,0.05,0.49,0.17,0.61c0.11,0.13,0.29,0.19,0.54,0.19c0.59,0,1.33-0.46,2.21-1.39
			s1.82-2.22,2.82-3.88l1.46,0.42c-0.97,2.01-1.89,3.61-2.75,4.82c-0.86,1.21-1.72,2.11-2.58,2.7
			C39.18,297.03,38.23,297.33,37.19,297.33z"
          fill={fill}
        />
        <path
          d="M50.02,297.57c-1.22,0-2.2-0.39-2.94-1.17c-0.74-0.78-1.1-1.97-1.1-3.57c0-2.26,0.85-4.83,2.56-7.73s3.79-5.37,6.25-7.4
			s4.71-3.06,6.75-3.06c0.85,0,1.64,0.16,2.37,0.47c0.74,0.31,1.29,0.78,1.67,1.41l-1.41,4.7c-0.5-1.38-1.24-2.41-2.21-3.1
			c-0.97-0.69-1.94-1.05-2.91-1.08c-0.91,0.03-2.11,0.92-3.6,2.66s-2.8,3.75-3.95,6.04s-1.71,4.2-1.71,5.73
			c0,0.82,0.17,1.4,0.52,1.76c0.34,0.36,0.8,0.54,1.36,0.54c0.94,0,1.97-0.53,3.1-1.6c1.13-1.06,2.38-2.62,3.76-4.65
			c1.38-2.04,3.07-4.76,5.08-8.18h2.63l-1.79,4.42h-1.97c-2.29,3.82-4.03,6.63-5.24,8.41c-1.21,1.79-2.37,3.13-3.5,4.04
			S51.37,297.57,50.02,297.57z M61.45,297.33c-0.81,0-1.49-0.22-2.02-0.66c-0.53-0.44-0.8-1.14-0.8-2.11c0-0.78,0.34-1.97,1.01-3.55
			s1.89-4,3.64-7.26l1.08-5.55l-0.19-1.5l0.99-3.01h3.85l-6.16,18.05c-0.16,0.44-0.24,0.82-0.26,1.13
			c-0.02,0.31,0.04,0.54,0.17,0.68c0.12,0.14,0.31,0.21,0.56,0.21c0.44,0,0.92-0.29,1.46-0.87c0.53-0.58,1.17-1.53,1.93-2.84
			l1.41,0.38c-0.88,2.1-1.9,3.78-3.06,5.03C63.91,296.7,62.7,297.33,61.45,297.33z"
          fill={fill}
        />
        <path
          d="M85.79,297.33c-0.82,0-1.49-0.24-2.02-0.73s-0.8-1.17-0.8-2.04c0-0.56,0.1-1.17,0.31-1.81c0.2-0.64,0.46-1.37,0.78-2.19
			l3.43-7.99c0.6-1.6,0.89-2.73,0.89-3.38c0-0.53-0.13-0.92-0.4-1.17c-0.27-0.25-0.62-0.38-1.06-0.38c-1.25,0-2.68,0.99-4.28,2.98
			c-1.6,1.99-3.13,4.48-4.61,7.47c-1.47,2.99-2.7,5.93-3.67,8.81H70.8l4.89-16.31c0.34-0.97,0.52-1.71,0.52-2.21
			c0-0.28-0.06-0.48-0.19-0.59c-0.12-0.11-0.31-0.16-0.56-0.16c-0.38,0-0.84,0.3-1.39,0.89c-0.55,0.6-1.18,1.54-1.9,2.82l-1.46-0.42
			c1-2.13,2.05-3.81,3.15-5.03c1.1-1.22,2.37-1.83,3.81-1.83c0.78,0,1.4,0.22,1.86,0.66c0.45,0.44,0.68,1.07,0.68,1.88
			c0,0.91-0.25,2.03-0.75,3.36c-0.5,1.33-1.6,3.94-3.29,7.83h0.66c1.6-3.35,3.06-6.02,4.37-8.01c1.32-1.99,2.57-3.44,3.76-4.35
			c1.19-0.91,2.43-1.36,3.71-1.36c1.25,0,2.22,0.3,2.91,0.89c0.69,0.6,1.03,1.47,1.03,2.63c0,0.56-0.1,1.25-0.3,2.07
			c-0.2,0.82-0.48,1.61-0.82,2.4l-3.57,8.51c-0.28,0.56-0.49,1.04-0.63,1.43c-0.14,0.39-0.21,0.71-0.21,0.96
			c0,0.25,0.07,0.45,0.21,0.59c0.14,0.14,0.32,0.21,0.54,0.21c0.44,0,0.92-0.3,1.46-0.89c0.53-0.59,1.18-1.55,1.93-2.87l1.46,0.42
			c-0.97,2.16-2.01,3.85-3.13,5.08S87.17,297.33,85.79,297.33z"
          fill={fill}
        />
        <path
          d="M97.97,297.33c-0.75,0-1.42-0.23-2-0.71c-0.58-0.47-0.87-1.16-0.87-2.07c0-0.5,0.06-1.03,0.19-1.6
			c0.12-0.56,0.36-1.25,0.71-2.07l5.88-14.43l0.05-0.47l1.74-5.03l4.56-0.38l-2.58,5.41l-0.33,0.47l-5.73,15.23
			c0,0.03-0.01,0.05-0.02,0.05c-0.02,0-0.02,0.02-0.02,0.05c-0.16,0.5-0.24,0.88-0.24,1.13c0,0.28,0.05,0.49,0.17,0.61
			c0.11,0.12,0.29,0.19,0.54,0.19c0.59,0,1.27-0.32,2.02-0.96s1.68-1.72,2.77-3.22l1.46,0.38c-0.82,1.57-1.64,2.9-2.49,4
			c-0.85,1.1-1.75,1.94-2.73,2.54C100.05,297.03,99.03,297.33,97.97,297.33z M96.88,277.73l0.61-2.26h13.68l-0.66,2.26H96.88z"
          fill={fill}
        />
        <path
          d="M134.95,297.57c-2.57,0-4.93-0.5-7.07-1.5c-2.15-1-3.88-2.54-5.19-4.61c-1.32-2.07-1.97-4.64-1.97-7.71
			c0-3.32,0.74-6.48,2.21-9.47c1.47-2.99,3.67-5.4,6.6-7.24s6.43-2.75,10.5-2.75c1.94,0,3.49,0.15,4.65,0.45
			c1.16,0.3,2.21,0.67,3.15,1.1c0.5,0.25,0.88,0.38,1.13,0.38c0.28,0,0.54-0.12,0.78-0.35c0.23-0.23,0.51-0.62,0.82-1.15h2.02
			l-2.91,10.2h-2.21c0.03-0.19,0.05-0.38,0.05-0.56c0-0.19,0-0.33,0-0.42c0-0.16,0-0.28,0-0.38c0-0.09,0-0.2,0-0.33
			c0-1.79-0.35-3.18-1.06-4.18c-0.71-1-1.6-1.7-2.68-2.09c-1.08-0.39-2.29-0.59-3.64-0.59c-3.13,0-5.77,0.85-7.9,2.54
			c-2.13,1.69-3.73,3.89-4.79,6.58c-1.07,2.69-1.6,5.58-1.6,8.65c0,2.54,0.43,4.66,1.29,6.37c0.86,1.71,2,2.97,3.43,3.78
			c1.43,0.82,2.98,1.22,4.68,1.22c2.26,0,4.21-0.85,5.85-2.56c1.64-1.71,3.12-4.11,4.44-7.21h2.21l-2.68,9.35
			c-1.57,0.78-3.19,1.39-4.86,1.83C138.52,297.35,136.77,297.57,134.95,297.57z"
          fill={fill}
        />
        <path
          d="M157.37,297.57c-2.1,0-3.79-0.56-5.08-1.69c-1.29-1.13-1.93-2.93-1.93-5.41c0-1.66,0.31-3.41,0.92-5.24
			c0.61-1.83,1.45-3.6,2.51-5.29c1.19-1.79,2.53-3.21,4.02-4.28c1.49-1.06,3.25-1.6,5.29-1.6c2.1,0,3.79,0.56,5.08,1.69
			s1.93,2.93,1.93,5.41c0,1.69-0.31,3.45-0.92,5.26c-0.61,1.82-1.45,3.57-2.51,5.26c-1.19,1.79-2.53,3.21-4.02,4.28
			C161.17,297.03,159.41,297.57,157.37,297.57z M156.85,295.64c1.32,0,2.52-0.61,3.62-1.83c1.1-1.22,2.12-2.84,3.06-4.84
			c0.88-1.88,1.54-3.67,2-5.36c0.45-1.69,0.68-3.12,0.68-4.28c0-1.16-0.23-2-0.68-2.54c-0.45-0.53-1.07-0.8-1.86-0.8
			c-1.35,0-2.57,0.61-3.67,1.83s-2.12,2.84-3.05,4.84c-0.88,1.88-1.54,3.66-2,5.33c-0.45,1.68-0.68,3.11-0.68,4.3
			c0,1.19,0.23,2.04,0.68,2.56C155.4,295.38,156.04,295.64,156.85,295.64z"
          fill={fill}
        />
        <path
          d="M175.28,297.38c-0.88,0-1.61-0.29-2.21-0.87c-0.6-0.58-0.89-1.32-0.89-2.23c0-0.91,0.3-1.66,0.89-2.26
			c0.59-0.59,1.33-0.89,2.21-0.89s1.61,0.3,2.19,0.89c0.58,0.6,0.87,1.35,0.87,2.26c0,0.91-0.29,1.65-0.87,2.23
			C176.88,297.09,176.15,297.38,175.28,297.38z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M52.83,333.17c-0.76,0-1.4-0.13-1.93-0.39s-0.95-0.68-1.25-1.25c-0.31-0.58-0.46-1.31-0.46-2.19
			c0-1.35,0.35-2.79,1.05-4.31c0.7-1.52,1.65-2.79,2.83-3.82c1.19-1.03,2.47-1.54,3.83-1.54c0.86,0,1.55,0.17,2.05,0.5
			s0.76,0.89,0.76,1.66c0,0.86-0.38,1.69-1.15,2.48s-1.84,1.41-3.21,1.85c-1.38,0.44-2.94,0.63-4.68,0.55v-0.86
			c1.3,0,2.43-0.2,3.4-0.59c0.97-0.4,1.71-0.89,2.23-1.48c0.51-0.59,0.77-1.18,0.77-1.76c0-0.34-0.09-0.59-0.26-0.76
			s-0.43-0.24-0.77-0.24c-0.68,0-1.4,0.39-2.15,1.17s-1.36,1.75-1.85,2.9c-0.49,1.15-0.73,2.26-0.73,3.32
			c0,0.92,0.22,1.58,0.65,1.97c0.43,0.4,0.99,0.59,1.67,0.59c0.59,0,1.17-0.2,1.73-0.59c0.56-0.4,1.09-1.01,1.59-1.84l0.81,0.27
			c-0.23,0.63-0.56,1.27-0.99,1.93s-0.97,1.22-1.65,1.7C54.46,332.93,53.69,333.17,52.83,333.17z"
          fill={fill}
        />
        <path
          d="M62.06,333.17c-0.52,0-1.03-0.08-1.51-0.24c-0.49-0.16-0.86-0.4-1.13-0.73c-0.27-0.32-0.4-0.7-0.4-1.13
			c0-0.45,0.12-0.82,0.38-1.12c0.25-0.3,0.61-0.45,1.08-0.45c0.34,0,0.62,0.09,0.82,0.28c0.21,0.19,0.38,0.46,0.53,0.8
			c0.07,0.13,0.14,0.32,0.22,0.57c0.05,0.23,0.12,0.44,0.2,0.62c0.08,0.18,0.19,0.36,0.34,0.54c0.5-0.04,0.95-0.23,1.34-0.58
			c0.39-0.35,0.58-0.78,0.58-1.28c0-0.29-0.09-0.62-0.26-0.99c-0.17-0.37-0.5-0.96-0.99-1.77l-0.51-0.81
			c-0.54-0.86-0.9-1.53-1.09-2.01c-0.19-0.48-0.28-0.98-0.28-1.5c0-0.74,0.2-1.39,0.61-1.96c0.41-0.57,0.96-1,1.66-1.3
			c0.7-0.3,1.46-0.45,2.27-0.45c0.52,0,1.03,0.08,1.51,0.24c0.49,0.16,0.86,0.41,1.13,0.73c0.27,0.32,0.41,0.7,0.41,1.13
			c0,0.45-0.13,0.82-0.38,1.12s-0.61,0.45-1.08,0.45c-0.34,0-0.61-0.09-0.81-0.28c-0.2-0.19-0.38-0.45-0.54-0.8
			c-0.04-0.09-0.07-0.18-0.11-0.28s-0.06-0.19-0.08-0.28c-0.05-0.23-0.12-0.44-0.2-0.61s-0.19-0.36-0.34-0.55
			c-0.52,0.04-0.98,0.23-1.36,0.58c-0.39,0.35-0.58,0.78-0.58,1.28c0,0.29,0.08,0.62,0.26,0.99s0.51,0.96,1.01,1.77l0.51,0.81
			c0.52,0.86,0.88,1.53,1.07,2c0.19,0.47,0.28,0.97,0.28,1.51c0,0.74-0.2,1.39-0.61,1.96c-0.41,0.57-0.96,1-1.65,1.3
			C63.65,333.02,62.89,333.17,62.06,333.17z"
          fill={fill}
        />
        <path
          d="M70.87,333.03c-0.43,0-0.82-0.13-1.15-0.41c-0.33-0.27-0.5-0.67-0.5-1.19c0-0.29,0.04-0.59,0.11-0.92
			c0.07-0.32,0.21-0.72,0.4-1.19l3.38-8.29l0.03-0.27l1-2.89l2.62-0.22l-1.49,3.11l-0.19,0.27l-3.29,8.75c0,0.02,0,0.03-0.01,0.03
			c-0.01,0-0.01,0.01-0.01,0.03c-0.09,0.29-0.13,0.5-0.13,0.65c0,0.16,0.03,0.28,0.09,0.35c0.06,0.07,0.17,0.11,0.31,0.11
			c0.34,0,0.73-0.18,1.16-0.55c0.43-0.37,0.96-0.99,1.59-1.85l0.84,0.22c-0.47,0.9-0.95,1.67-1.43,2.3
			c-0.49,0.63-1.01,1.12-1.57,1.46C72.06,332.86,71.48,333.03,70.87,333.03z M70.25,321.77l0.35-1.3h7.86l-0.38,1.3H70.25z"
          fill={fill}
        />
        <path
          d="M81.59,332.79l0.65-2.51h7.43c0.79,0,1.42-0.09,1.89-0.27c0.47-0.18,0.91-0.54,1.32-1.09c0.41-0.55,0.81-1.4,1.19-2.55
			h1.19l-1.84,6.43H81.59z M86.5,327.47c1.58-1.04,2.88-2.02,3.89-2.93c1.01-0.91,1.77-1.81,2.28-2.71c0.51-0.9,0.77-1.82,0.77-2.75
			c0-0.92-0.29-1.65-0.86-2.2c-0.58-0.55-1.43-0.82-2.56-0.82c-1.31,0-2.33,0.35-3.04,1.04c-0.71,0.69-1.22,1.66-1.53,2.9h-1.19
			c0.22-1.75,0.84-3.17,1.86-4.28c1.03-1.11,2.49-1.66,4.4-1.66c1.57,0,2.89,0.38,3.97,1.13c1.08,0.76,1.62,1.91,1.62,3.46
			c0,1.15-0.28,2.21-0.84,3.16c-0.56,0.95-1.4,1.9-2.54,2.85c-1.13,0.95-2.7,2.05-4.7,3.31l-3.75,2.32h-2.05L86.5,327.47z"
          fill={fill}
        />
        <path
          d="M102.56,333.17c-1.12,0-2.09-0.23-2.92-0.69s-1.48-1.15-1.94-2.08c-0.47-0.93-0.7-2.07-0.7-3.42c0-1.78,0.33-3.69,1-5.71
			c0.67-2.02,1.65-3.73,2.94-5.13c1.3-1.39,2.83-2.09,4.59-2.09c1.08,0,2.04,0.23,2.88,0.69s1.49,1.15,1.94,2.08
			c0.46,0.93,0.69,2.07,0.69,3.42c0,1.78-0.33,3.69-0.99,5.71c-0.66,2.03-1.63,3.74-2.92,5.13
			C105.85,332.47,104.33,333.17,102.56,333.17z M103.32,330.57c1.31,0,2.42-0.48,3.31-1.43c0.89-0.95,1.56-2.12,2.01-3.48
			s0.67-2.68,0.67-3.94c0-0.97-0.17-1.84-0.53-2.59c-0.35-0.76-0.86-1.36-1.53-1.81s-1.5-0.68-2.51-0.68
			c-1.31,0-2.42,0.48-3.32,1.43s-1.57,2.12-2,3.48c-0.43,1.37-0.65,2.68-0.65,3.94c0,0.97,0.17,1.84,0.5,2.59s0.84,1.36,1.53,1.81
			C101.49,330.35,102.33,330.57,103.32,330.57z"
          fill={fill}
        />
        <path
          d="M110.18,332.79l0.65-2.51h7.43c0.79,0,1.42-0.09,1.89-0.27c0.47-0.18,0.91-0.54,1.32-1.09c0.41-0.55,0.81-1.4,1.19-2.55
			h1.19l-1.84,6.43H110.18z M115.09,327.47c1.58-1.04,2.88-2.02,3.89-2.93c1.01-0.91,1.77-1.81,2.28-2.71
			c0.51-0.9,0.77-1.82,0.77-2.75c0-0.92-0.29-1.65-0.86-2.2c-0.58-0.55-1.43-0.82-2.56-0.82c-1.31,0-2.33,0.35-3.04,1.04
			c-0.71,0.69-1.22,1.66-1.53,2.9h-1.19c0.22-1.75,0.84-3.17,1.86-4.28c1.03-1.11,2.49-1.66,4.4-1.66c1.57,0,2.89,0.38,3.97,1.13
			c1.08,0.76,1.62,1.91,1.62,3.46c0,1.15-0.28,2.21-0.84,3.16c-0.56,0.95-1.4,1.9-2.54,2.85c-1.13,0.95-2.7,2.05-4.7,3.31
			l-3.75,2.32h-2.05L115.09,327.47z"
          fill={fill}
        />
        <path
          d="M130.16,333.17c-1.24,0-2.31-0.16-3.2-0.47c-0.89-0.31-1.56-0.75-2.01-1.31c-0.45-0.56-0.67-1.18-0.67-1.86
			c0-0.63,0.16-1.13,0.49-1.5c0.32-0.37,0.76-0.55,1.3-0.55c0.36,0,0.66,0.12,0.9,0.35s0.39,0.49,0.45,0.76
			c0.04,0.22,0.05,0.45,0.04,0.69c-0.01,0.24-0.03,0.48-0.07,0.72c-0.04,0.23-0.05,0.47-0.05,0.71s0.01,0.46,0.03,0.66
			c0.4,0.22,0.8,0.38,1.21,0.5s0.89,0.18,1.43,0.18c0.99,0,1.81-0.2,2.46-0.59c0.65-0.4,1.12-0.91,1.42-1.54s0.45-1.31,0.45-2.05
			c0-1.42-0.57-2.38-1.7-2.86c-1.13-0.49-2.61-0.61-4.43-0.38l1.67-2.19c1.24-0.16,2.39-0.1,3.46,0.18
			c1.06,0.28,1.92,0.81,2.57,1.61c0.65,0.79,0.97,1.84,0.97,3.13c0,1.06-0.24,2.03-0.71,2.92c-0.48,0.88-1.22,1.59-2.23,2.12
			C132.9,332.9,131.65,333.17,130.16,333.17z M126.73,319.99l1.59-5.56h10.83l-0.65,2.51h-6.94c-0.77,0-1.35,0.05-1.71,0.16
			c-0.37,0.11-0.69,0.37-0.97,0.8s-0.6,1.12-0.96,2.09H126.73z M128.19,324.61l0.51-1.7l7.86-5.97h1.94l-7.72,5.8L128.19,324.61z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default Logo;

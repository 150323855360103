import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import colors from './colors';

const pillStyle = css`
  border-radius: 32px;
  border: black solid 1px;
  color: ${colors.galaxy};
  display: inline-block;
  letter-spacing: 2px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 0.85rem;
`;

const PillLayout = styled.div`
  ${pillStyle}
`;

const PillLinkLayout = styled(Link)`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  ${pillStyle}
`;

type PillProps = {
  to?: string | undefined | null;
  text: string;
};
const Pill = ({ to, text }: PillProps) => {
  if (to) {
    return (
      <div>
        <PillLinkLayout to={to}>{text}</PillLinkLayout>
      </div>
    );
  }

  return (
    <div>
      <PillLayout>{text}</PillLayout>
    </div>
  );
};

Pill.defaultProps = {
  to: undefined,
};

export default Pill;

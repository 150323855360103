import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';

const ExternalOutboundLink = styled(OutboundLink)`
  display: inline-block;
`;

type ExternalLinkProps = {
  href: string;
  text: string;
};

const ExternalLink = ({ text, href }: ExternalLinkProps) => (
  <ExternalOutboundLink aria-label={text} href={href} rel="noreferrer" target="_blank">
    {text}
  </ExternalOutboundLink>
);

export default ExternalLink;

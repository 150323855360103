import React from 'react';
import styled from 'styled-components';

import Image from '../atoms/Image';

const ImageWrapper = styled.div`
  height: 60px;
  width: 60px;
`;

type Author = { name: string; imageName: string };

const authors: Author[] = [{ imageName: 'alysia.png', name: 'Alysia Lynn' }];

const defaultImageName = 'brandmark.png';

type AuthorImageProps = {
  name?: string | undefined | null;
};
const AuthorImage = ({ name }: AuthorImageProps) => {
  const author = authors.find((auth: Author) => auth.name === name);
  return (
    <ImageWrapper>
      <Image
        alt={author?.name || 'Author'}
        imageName={author?.imageName || defaultImageName}
        imgStyle={{
          borderRadius: '50%',
        }}
      />
    </ImageWrapper>
  );
};

AuthorImage.defaultProps = {
  name: undefined,
};

export default AuthorImage;
